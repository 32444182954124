import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Flex, FlexSpan, Tabs } from "../../ui";
import { UsersTab } from "./UsersTab";
import { OrdersTab } from "./OrdersTab";
import { EventsTab } from "./EventsTab";
var StatisticsTabs;
(function (StatisticsTabs) {
    StatisticsTabs["Users"] = "Users";
    StatisticsTabs["Orders"] = "Orders";
    StatisticsTabs["Events"] = "Events";
})(StatisticsTabs || (StatisticsTabs = {}));
export const StatisticsPage = () => {
    const [currentTab, setCurrentTab] = useState(StatisticsTabs.Users);
    return (_jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { children: _jsx(Tabs, { defaultValue: StatisticsTabs.Users, items: [
                        { label: "Users", value: StatisticsTabs.Users },
                        { label: "Orders", value: StatisticsTabs.Orders },
                        { label: "Events", value: StatisticsTabs.Events },
                    ], onChange: setCurrentTab }) }), _jsxs(FlexSpan, { children: [_jsx(UsersTab, { visible: currentTab === StatisticsTabs.Users }), _jsx(OrdersTab, { visible: currentTab === StatisticsTabs.Orders }), _jsx(EventsTab, { visible: currentTab === StatisticsTabs.Events })] })] }));
};
