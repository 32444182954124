import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { AddressConfirmContainer, AddressConfirmAddress } from "./styled";
import { Button } from "../../../../ui";
export const AddressConfirm = ({ address, onConfirm }) => {
    const ref = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    useEffect(() => {
        setContainerWidth(ref.current.offsetWidth);
    }, [ref.current]); // eslint-disable-line
    return (_jsxs(AddressConfirmContainer, { ref: ref, width: containerWidth, children: [_jsx(AddressConfirmAddress, { children: address }), _jsx(Button, { height: "24px", ml: "8px", onClick: onConfirm, children: "Ok" })] }));
};
