import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { Marker } from "react-map-gl";
import { GeocoderControlWrapper, GeocoderControlContainer, GeocoderControlInput, GeocoderControlClear, GeocoderControlSuggestions, GeocoderControlSuggestion, SearchCenter, } from "./styled";
import { Icon } from "../../../../ui";
import { useClickOutside } from "../../../../hooks";
import { isTouch } from "../../../../utils/isTouch";
export const GeocoderControl = ({ placeholder, defaultValue, value, autofocus, onChange, onPick, }) => {
    const inputRef = useRef(null);
    const [ownValue, setOwnValue] = useState(value || defaultValue || "");
    const [suggestions, setSuggestions] = useState([]);
    const [activeItem, setActiveItem] = useState(-1);
    const [picked, setPicked] = useState(false);
    const [searchCenter, setSearchCenter] = useState();
    const controlRef = useClickOutside(() => setSuggestions([]));
    const fetchGeocoder = useCallback((text) => {
        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?country=ZA&access_token=${process.env.ACCESS_TOKEN}`)
            .then(response => response.json())
            .then(response => setSuggestions(response.features));
    }, []);
    const onValueChange = useCallback((e) => {
        const currentValue = e.target.value;
        onChange && onChange(currentValue);
        setOwnValue(currentValue);
        setActiveItem(-1);
        setPicked(false);
        setSearchCenter(undefined);
        fetchGeocoder(currentValue);
    }, [fetchGeocoder, onChange]);
    const onValueClear = useCallback(() => {
        onChange && onChange("");
        setOwnValue("");
        setSuggestions([]);
        setActiveItem(-1);
        setPicked(false);
        setSearchCenter(undefined);
        !isTouch() && inputRef.current.focus();
    }, [onChange]);
    const onPickFeature = useCallback((index) => {
        onPick && onPick(suggestions[index ?? activeItem]);
        setActiveItem(-1);
        setPicked(true);
        setSearchCenter(suggestions[index ?? activeItem]?.center);
    }, [activeItem, onPick, suggestions]);
    useEffect(() => {
        if (inputRef.current && autofocus) {
            inputRef.current.focus();
        }
    }, [autofocus, inputRef]);
    useEffect(() => {
        const onKeyDown = (e) => {
            if (!suggestions.length) {
                return;
            }
            switch (e.code) {
                case "ArrowDown":
                    activeItem < suggestions.length - 1 && setActiveItem(activeItem + 1);
                    break;
                case "ArrowUp":
                    activeItem > 0 && setActiveItem(activeItem - 1);
                    break;
                case "Enter":
                    onPickFeature();
                    break;
                default:
            }
        };
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [activeItem, suggestions]); // eslint-disable-line
    useEffect(() => {
        if (value === "") {
            setSearchCenter(undefined);
        }
    }, [value]);
    return (_jsxs(_Fragment, { children: [_jsxs(GeocoderControlWrapper, { ref: controlRef, children: [_jsxs(GeocoderControlContainer, { children: [_jsx(GeocoderControlInput, { ref: inputRef, type: "text", placeholder: placeholder ?? "Search Address", value: value ?? ownValue, onChange: onValueChange, onKeyDown: e => {
                                    if (e.code === "Enter" && activeItem === -1 && suggestions.length) {
                                        onPickFeature(0);
                                    }
                                } }), (value ?? ownValue) && (_jsx(GeocoderControlClear, { children: _jsx(Icon, { icon: "close", size: 14, color: "#8275AD", hoverColor: "#5732d3", onClick: onValueClear }) }))] }), suggestions.length > 0 && !picked && (_jsx(GeocoderControlSuggestions, { children: suggestions.map((item, index) => {
                            return (_jsx(GeocoderControlSuggestion, { className: activeItem === index ? "active" : undefined, onClick: () => onPickFeature(index), children: item.place_name }, `geocoder-control-suggestion-${item.id}`));
                        }) }))] }), isTouch() && searchCenter !== undefined && (_jsx(Marker, { longitude: searchCenter[0], latitude: searchCenter[1], children: _jsx(SearchCenter, {}) }))] }));
};
