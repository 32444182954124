import { useMemo } from "react";
import { useIndustries } from "../../../hooks";
export const useIndustiesOptions = () => {
    const [industries] = useIndustries();
    return useMemo(() => [
        { value: undefined, label: "All" },
        ...(industries?.map(industry => ({
            value: industry.id,
            label: industry.title,
        })) ?? []),
    ], [industries]);
};
