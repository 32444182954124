import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { center as turfCenter, polygon as turfPolygon } from "@turf/turf";
import { GeolocateControl, Layer, Marker, NavigationControl, Source, } from "react-map-gl";
import { AppWrapper, LENS_BORDER_WIDTH } from "./styled";
import { AppHeader } from "../../components/AppHeader";
import { Map } from "../../components/Map";
import { GeocoderControl } from "./components/GeocoderControl";
import { ModeControl } from "./components/ModeControl";
import { LayersControl } from "./components/LayersControl";
import { LensResultDialog } from "./components/LensResultDialog";
import { ClickResultDialog } from "./components/ClickResultDialog";
import { CustomMarker } from "./components/CustomMarker";
import { ClickOnTheMap } from "./components/ClickOnTheMap";
import { AddressConfirm } from "./components/AddressConfirm";
import { useSites, useUser } from "../../hooks";
import { metersPerPixel } from "../../utils/metersPerPixel";
import { debounce } from "../../utils/debounce";
import { isTouch } from "../../utils/isTouch";
import { api } from "../../api";
import { SelectionModes } from "./components/ModeControl/types";
const DEFAULT_LNG = 28.086095;
const DEFAULT_LAT = -26.119949;
const DEFAULT_ZOOM = 15;
const MIN_ZOOM = 13;
const MAX_ZOOM = 16;
const SEARCH_RADIUS = 600;
// eslint-disable-next-line max-lines-per-function
export const MainPage = () => {
    const [searchParams] = useSearchParams();
    const coordinatesQueryParam = useMemo(() => searchParams.get("c"), [searchParams]);
    const [mainMapViewport, setMainMapViewport] = useState({});
    const [lensViewport, setLensViewport] = useState();
    const [selectedPoint, setSelectedPoint] = useState();
    const [selectedFeature, setSelectedFeature] = useState();
    const [selectedFeatureData, setSelectedFeatureData] = useState();
    const [selectedPointConfirm, setSelectedPointConfirm] = useState();
    const [selectionMode, setSelectionMode] = useState(SelectionModes.Click);
    const [lensRadius, setLensRadius] = useState(Math.round(SEARCH_RADIUS / metersPerPixel(DEFAULT_ZOOM, DEFAULT_LAT)));
    const [mousePosition, setMousePosition] = useState();
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [zoomingMap, setZoomingMap] = useState(false);
    const [draggingMap, setDraggingMap] = useState(false);
    const mainMap = useRef();
    const lensMap = useRef();
    const [mapStyleType, setMapStyleType] = useState();
    const [address, setAddress] = useState("");
    const [addressConfirm, setAddressConfirm] = useState("");
    const hoveredPolygonId = useRef(null);
    const activePolygonId = useRef(null);
    const [user] = useUser();
    const [sites, setSites] = useSites();
    const neverAutoClicked = useRef(true);
    const mapStyle = useMemo(() => (mapStyleType === 1 ? "mapbox://styles/mapbox/satellite-streets-v12" : "mapbox://styles/mapbox/light-v11"), [mapStyleType]);
    const lensLayer = useMemo(() => {
        return {
            id: "lens-layer",
            type: "fill",
            "source-layer": process.env.LENS_SOURCE_LAYER,
            paint: {
                "fill-color": selectedPoint ? "#7d66db" : "#9f9f9f",
                "fill-opacity": 0.8,
            },
        };
    }, [selectedPoint]);
    const getClickLayer = useCallback((source, sourceLayer) => {
        return {
            id: `${source}-layer`,
            type: "fill",
            source,
            "source-layer": sourceLayer,
            paint: {
                "fill-color": [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    "#dde0ff",
                    ["boolean", ["feature-state", "clicked"], false],
                    "#5732d3",
                    "#9f9f9f",
                ],
                "fill-opacity": 0.8,
            },
        };
    }, []);
    const resetMapState = useCallback((ignoreAddressReset) => {
        setMousePosition(undefined);
        setSelectedPoint(undefined);
        setSelectedFeature(undefined);
        setSelectedFeatureData(undefined);
        setSelectedPointConfirm(undefined);
        !ignoreAddressReset && setAddress("");
        setAddressConfirm("");
    }, []);
    const fetchReverseGeocoder = useCallback((lon, lat, callback) => {
        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${process.env.ACCESS_TOKEN}`)
            .then(response => response.json())
            .then(callback);
    }, []);
    const onZoom = useCallback((e) => {
        setZoom(e.viewState.zoom);
        setLensRadius(Math.round(SEARCH_RADIUS / metersPerPixel(e.viewState.zoom, e.viewState.latitude)));
        resetMapState(true);
    }, [resetMapState]);
    const onMouseMove = useCallback((e, shouldCancel) => {
        if (selectionMode === SelectionModes.Lens) {
            if (isTouch()) {
                if (!mousePosition) {
                    setMousePosition([window.innerWidth / 2, window.innerHeight / 2]);
                    setMainMapViewport({
                        longitude: e.lngLat.lng,
                        latitude: e.lngLat.lat,
                    });
                    setLensViewport({
                        longitude: e.lngLat.lng,
                        latitude: e.lngLat.lat,
                    });
                    setSelectedPointConfirm([e.lngLat.lng, e.lngLat.lat]);
                    fetchReverseGeocoder(e.lngLat.lng, e.lngLat.lat, response => setAddressConfirm(response.features[0]?.place_name));
                }
                else {
                    resetMapState();
                }
                return;
            }
            if (shouldCancel) {
                return;
            }
            setMousePosition([e.originalEvent.pageX - 1, e.originalEvent.pageY - 57]);
            setLensViewport({
                longitude: e.lngLat.lng,
                latitude: e.lngLat.lat,
            });
        }
        if (selectionMode === SelectionModes.Click) {
            if (isTouch() || selectedFeature !== undefined || !mainMap.current) {
                return;
            }
            const features = mainMap.current.queryRenderedFeatures(e.point, {
                layers: ["click-source-1-layer", "click-source-2-layer", "click-source-3-layer"].filter(item => !!mainMap.current.getLayer(item)) ?? [],
            });
            if (hoveredPolygonId.current !== null) {
                mainMap.current.setFeatureState({ source: "click-source-1", sourceLayer: process.env.CLICK_SOURCE_LAYER_1, id: hoveredPolygonId.current }, { hover: false });
                mainMap.current.setFeatureState({ source: "click-source-2", sourceLayer: process.env.CLICK_SOURCE_LAYER_2, id: hoveredPolygonId.current }, { hover: false });
                mainMap.current.setFeatureState({ source: "click-source-3", sourceLayer: process.env.CLICK_SOURCE_LAYER_3, id: hoveredPolygonId.current }, { hover: false });
            }
            if (features.length > 0) {
                hoveredPolygonId.current = features[0].id;
                mainMap.current.setFeatureState({ source: "click-source-1", sourceLayer: process.env.CLICK_SOURCE_LAYER_1, id: hoveredPolygonId.current }, { hover: true });
                mainMap.current.setFeatureState({ source: "click-source-2", sourceLayer: process.env.CLICK_SOURCE_LAYER_2, id: hoveredPolygonId.current }, { hover: true });
                mainMap.current.setFeatureState({ source: "click-source-3", sourceLayer: process.env.CLICK_SOURCE_LAYER_3, id: hoveredPolygonId.current }, { hover: true });
            }
        }
    }, [fetchReverseGeocoder, mousePosition, resetMapState, selectionMode, selectedFeature]);
    const processFeatureSelect = useCallback((e) => {
        const features = mainMap.current.queryRenderedFeatures(e.point, {
            layers: ["click-source-1-layer", "click-source-2-layer", "click-source-3-layer"].filter(item => !!mainMap.current.getLayer(item)) ?? [],
        });
        if (activePolygonId.current !== null) {
            mainMap.current.setFeatureState({ source: "click-source-1", sourceLayer: process.env.CLICK_SOURCE_LAYER_1, id: activePolygonId.current }, { clicked: false });
            mainMap.current.setFeatureState({ source: "click-source-2", sourceLayer: process.env.CLICK_SOURCE_LAYER_2, id: activePolygonId.current }, { clicked: false });
            mainMap.current.setFeatureState({ source: "click-source-3", sourceLayer: process.env.CLICK_SOURCE_LAYER_3, id: activePolygonId.current }, { clicked: false });
        }
        if (features.length > 0) {
            neverAutoClicked.current = false;
            activePolygonId.current = features[0].id;
            mainMap.current.setFeatureState({ source: "click-source-1", sourceLayer: process.env.CLICK_SOURCE_LAYER_1, id: activePolygonId.current }, { clicked: true });
            mainMap.current.setFeatureState({ source: "click-source-2", sourceLayer: process.env.CLICK_SOURCE_LAYER_2, id: activePolygonId.current }, { clicked: true });
            mainMap.current.setFeatureState({ source: "click-source-3", sourceLayer: process.env.CLICK_SOURCE_LAYER_3, id: activePolygonId.current }, { clicked: true });
            if (isTouch()) {
                mainMap.current.easeTo({
                    center: {
                        lng: turfCenter(turfPolygon(features[0].geometry.coordinates))?.geometry
                            .coordinates[0],
                        lat: turfCenter(turfPolygon(features[0].geometry.coordinates))?.geometry
                            .coordinates[1],
                    },
                    padding: {
                        top: 400,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                });
            }
            setSelectedFeature(features[0].properties.OBJECTID);
            if (features[0].properties?.STREET_ADDRESS?.trim()) {
                setAddress(features[0].properties.STREET_ADDRESS);
                setSelectedFeatureData({
                    id: features[0].id,
                    type: features[0].type,
                    geometry: features[0].geometry,
                    properties: features[0].properties,
                });
            }
            else {
                fetchReverseGeocoder(e.lngLat.lng, e.lngLat.lat, response => {
                    setAddress(response.features[0]?.place_name);
                    setSelectedFeatureData({
                        id: features[0].id,
                        type: features[0].type,
                        geometry: features[0].geometry,
                        properties: {
                            ...features[0].properties,
                            STREET_ADDRESS: response.features[0]?.place_name,
                        },
                    });
                });
            }
        }
        else {
            setSelectedFeature(undefined);
            setSelectedFeatureData(undefined);
        }
    }, [fetchReverseGeocoder]);
    const onMainMapClick = useCallback((e) => {
        if (selectionMode === SelectionModes.Lens) {
            if (selectedPoint !== undefined) {
                resetMapState();
            }
            return;
        }
        if (selectionMode === SelectionModes.Click && mainMap.current) {
            processFeatureSelect(e);
        }
    }, [processFeatureSelect, resetMapState, selectedPoint, selectionMode]);
    const onLensMapClick = useCallback((e) => {
        if (!selectedPoint && !isTouch()) {
            setSelectedPoint([e.lngLat.lng, e.lngLat.lat]);
            fetchReverseGeocoder(e.lngLat.lng, e.lngLat.lat, response => setAddress(response.features[0]?.place_name));
        }
        else {
            resetMapState();
        }
    }, [selectedPoint, fetchReverseGeocoder, resetMapState]);
    const onGeocoderResult = useCallback((feature) => {
        if (!feature) {
            return;
        }
        const { center, place_name } = feature;
        if (!center) {
            return;
        }
        const updatedViewport = {
            longitude: center[0],
            latitude: center[1],
        };
        setMainMapViewport(updatedViewport);
        if (lensMap.current) {
            setMousePosition(undefined);
        }
        setAddress(place_name);
    }, []);
    useEffect(() => {
        if (selectedFeature === undefined) {
            setAddress("");
            if (activePolygonId.current !== null) {
                mainMap.current.setFeatureState({ source: "click-source-1", sourceLayer: process.env.CLICK_SOURCE_LAYER_1, id: activePolygonId.current }, { clicked: false });
                mainMap.current.setFeatureState({ source: "click-source-2", sourceLayer: process.env.CLICK_SOURCE_LAYER_2, id: activePolygonId.current }, { clicked: false });
                mainMap.current.setFeatureState({ source: "click-source-3", sourceLayer: process.env.CLICK_SOURCE_LAYER_3, id: activePolygonId.current }, { clicked: false });
            }
            activePolygonId.current = null;
        }
        else if (hoveredPolygonId.current !== null) {
            mainMap.current.setFeatureState({ source: "click-source-1", sourceLayer: process.env.CLICK_SOURCE_LAYER_1, id: hoveredPolygonId.current }, { hover: false });
            mainMap.current.setFeatureState({ source: "click-source-2", sourceLayer: process.env.CLICK_SOURCE_LAYER_2, id: hoveredPolygonId.current }, { hover: false });
            mainMap.current.setFeatureState({ source: "click-source-3", sourceLayer: process.env.CLICK_SOURCE_LAYER_3, id: hoveredPolygonId.current }, { hover: false });
            hoveredPolygonId.current = null;
        }
    }, [selectedFeature]);
    useEffect(() => {
        resetMapState();
    }, [selectionMode]); // eslint-disable-line
    useEffect(() => {
        if (sites === undefined && user) {
            api.userSites.getUserSites().then(setSites);
        }
    }, [sites, user]); // eslint-disable-line
    return (_jsxs(AppWrapper, { selected: selectedPoint !== undefined, selectionMode: selectionMode, children: [_jsx(AppHeader, { isMap: true }), _jsxs(Map, { id: "oneCityMainMap", mapStyle: mapStyle, ...mainMapViewport, initialViewState: {
                    longitude: DEFAULT_LNG,
                    latitude: DEFAULT_LAT,
                    zoom: DEFAULT_ZOOM,
                }, touchPitch: false, minZoom: MIN_ZOOM, maxZoom: MAX_ZOOM, zoom: zoom, scrollZoom: selectedPoint === undefined, onZoomStart: () => setZoomingMap(true), onZoomEnd: () => setZoomingMap(false), onTouchStart: () => setZoomingMap(true), onTouchEnd: () => setZoomingMap(false), onZoom: onZoom, onMove: (e) => setMainMapViewport(e.viewState), onClick: onMainMapClick, onMouseMove: debounce((e) => {
                    onMouseMove(e, selectedPoint !== undefined || draggingMap);
                }, selectionMode === SelectionModes.Click ? 0 : 150), onMouseLeave: () => setMousePosition(undefined), onDragStart: () => (selectedPoint !== undefined || !!addressConfirm) && resetMapState(), onSourceData: () => {
                    if (coordinatesQueryParam && neverAutoClicked.current) {
                        const [lng, lat] = coordinatesQueryParam.split(",");
                        setMainMapViewport({
                            longitude: Number(lng),
                            latitude: Number(lat),
                        });
                        setZoom(MAX_ZOOM);
                        processFeatureSelect({
                            point: [
                                Math.round(document.getElementById("oneCityMainMap").offsetWidth / 2),
                                Math.round(document.getElementById("oneCityMainMap").offsetHeight / 2),
                            ],
                            lngLat: {
                                lng: Number(lng),
                                lat: Number(lat),
                            },
                        });
                    }
                }, onRender: e => (mainMap.current = e.target), children: [!zoomingMap && selectionMode === SelectionModes.Lens && (_jsxs(Map, { id: "oneCityLens", mapStyle: mapStyle, ...lensViewport, initialViewState: {
                            zoom: DEFAULT_ZOOM,
                        }, touchPitch: false, style: {
                            width: `${(lensRadius - LENS_BORDER_WIDTH) * 2}px`,
                            height: `${(lensRadius - LENS_BORDER_WIDTH) * 2}px`,
                            borderRadius: `${lensRadius * 2}px`,
                            left: `${(mousePosition?.[0] ?? 0) - lensRadius - LENS_BORDER_WIDTH}px`,
                            top: `${(mousePosition?.[1] ?? 0) - lensRadius - LENS_BORDER_WIDTH}px`,
                            visibility: mousePosition !== undefined || selectedPoint !== undefined ? "visible" : "hidden",
                            opacity: draggingMap ? 0 : 1,
                            borderColor: selectedPoint === undefined ? "#ffffff" : "#5732D3",
                        }, minZoom: MIN_ZOOM, maxZoom: MAX_ZOOM, zoom: zoom, scrollZoom: selectedPoint === undefined, onZoomStart: () => setZoomingMap(true), onZoomEnd: () => setZoomingMap(false), onTouchStart: () => setZoomingMap(true), onTouchEnd: () => setZoomingMap(false), onZoom: onZoom, onClick: onLensMapClick, onMouseMove: e => onMouseMove(e, selectedPoint !== undefined || mousePosition === undefined), onDragStart: () => {
                            setDraggingMap(true);
                            setAddressConfirm("");
                        }, onDrag: (e) => setMainMapViewport(e.viewState), onDragEnd: () => {
                            setDraggingMap(false);
                            setMousePosition(undefined);
                        }, onRender: e => (lensMap.current = e.target), children: [_jsx(Source, { type: "vector", url: process.env.LENS_SRC_URL, children: _jsx(Layer, { ...lensLayer }) }), selectedPoint !== undefined && (_jsx(Marker, { longitude: selectedPoint[0], latitude: selectedPoint[1], children: _jsx(CustomMarker, {}) })), selectedPointConfirm !== undefined && (_jsx(Marker, { longitude: selectedPointConfirm[0], latitude: selectedPointConfirm[1], children: _jsx(CustomMarker, {}) }))] })), _jsx(GeocoderControl, { value: address, onChange: value => {
                            resetMapState();
                            setAddress(value);
                        }, onPick: onGeocoderResult }), _jsx(ModeControl, { currentMode: selectionMode, onChangeMode: setSelectionMode }), _jsx(NavigationControl, { showCompass: false }), _jsx(GeolocateControl, {}), _jsx(LayersControl, { onSelect: setMapStyleType }), selectedPoint && _jsx(LensResultDialog, { coordinates: selectedPoint, address: address }), selectedFeature && _jsx(ClickResultDialog, { feature: selectedFeatureData, address: address }), !addressConfirm && !selectedPoint && _jsx(ClickOnTheMap, {}), addressConfirm && !zoomingMap && (_jsx(AddressConfirm, { address: addressConfirm, onConfirm: () => {
                            setAddress(addressConfirm);
                            setSelectedPoint(selectedPointConfirm);
                            setAddressConfirm("");
                            setSelectedPointConfirm(undefined);
                        } })), selectionMode === SelectionModes.Click && (_jsxs(_Fragment, { children: [_jsx(Source, { id: "click-source-1", type: "vector", url: process.env.CLICK_SRC_URL_1, promoteId: "OBJECTID", children: _jsx(Layer, { ...getClickLayer("click-source-1", process.env.CLICK_SOURCE_LAYER_1) }) }), _jsx(Source, { id: "click-source-2", type: "vector", url: process.env.CLICK_SRC_URL_2, promoteId: "OBJECTID", children: _jsx(Layer, { ...getClickLayer("click-source-2", process.env.CLICK_SOURCE_LAYER_2) }) }), _jsx(Source, { id: "click-source-3", type: "vector", url: process.env.CLICK_SRC_URL_3, promoteId: "OBJECTID", children: _jsx(Layer, { ...getClickLayer("click-source-3", process.env.CLICK_SOURCE_LAYER_3) }) })] }))] })] }));
};
