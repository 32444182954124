import styled from "styled-components";
import { Flex, FlexSpan } from "../../ui";
export const StatisticsWrapper = styled(Flex) `
  display: ${({ visible }) => (visible ? "flex" : "none")};
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  height: calc(var(--app-height) - 2.625rem);
`;
export const StatisticsFiltersContainer = styled(FlexSpan) `
  & > ${Flex} {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
      align-items: start;
    }
  }
`;
export const InputLabel = styled.span `
  font-size: 12px;
  color: #8275ad;
`;
export const StatisticsTableContainer = styled(FlexSpan) `
  flex-grow: 1;
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        text-align: left;
        border: 1px solid #e0e0e0;
        padding: 0.5rem;
      }
    }

    tbody {
      td {
        text-align: left;
        border: 1px solid #e0e0e0;
        padding: 0.5rem;
      }
    }
  }
`;
