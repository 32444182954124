import styled from "styled-components";
import { Button } from "../../../../ui";
import { Icon } from "../../../../ui/Icon/styled";
export const LayersControlWrapper = styled.div `
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 3;
  text-align: right;

  @media only screen and (max-width: 768px) {
    & {
      bottom: 8px;
      right: 8px;
    }
  }
`;
export const LayersButton = styled(Button) `
  position: relative;
  right: 0;
  background-color: ${({ active }) => active && "#dde0ff"};
  border-color: ${({ active }) => active && "#dde0ff"};
  color: ${({ active }) => active && "#5732d3"};

  ${Icon} {
    margin-right: 7px;

    @media only screen and (max-width: 768px) {
      & {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    & {
      width: 36px;
      height: 36px;
    }
  }
`;
export const LayersButtonLabel = styled.span `
  @media only screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
`;
