import { HttpClient } from './HttpClient';
import { Auth } from "./Auth";
import { Features } from "./Features";
import { Industries } from "./Industries";
import { Orders } from "./Orders";
import { Reports } from "./Reports";
import { Users } from "./Users";
import { UserSites } from "./UserSites";
import { Events } from "./Events";
export class Api {
    http;
    auth;
    features;
    industries;
    orders;
    reports;
    users;
    userSites;
    events;
    constructor(url) {
        this.http = new HttpClient(url);
        this.auth = new Auth(this.http);
        this.features = new Features(this.http);
        this.industries = new Industries(this.http);
        this.orders = new Orders(this.http);
        this.reports = new Reports(this.http);
        this.users = new Users(this.http);
        this.userSites = new UserSites(this.http);
        this.events = new Events(this.http);
    }
}
