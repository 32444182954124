export class Users {
    http;
    constructor(http) {
        this.http = http;
    }
    getUsers() {
        return this.http.get(`/api/users/statistics`).json();
    }
    getMe() {
        return this.http.get(`/api/users/me`).json();
    }
    updateMe(data) {
        return this.http.patch(`/api/users/me`, data).json();
    }
    checkPassword(data) {
        return this.http.post(`/api/users/checkPassword`, data).json();
    }
    updatePassword(data) {
        return this.http.post(`/api/users/updatePassword`, data).json();
    }
}
