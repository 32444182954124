import { pointOnFeature, lineString, polygon, multiPolygon } from "@turf/turf";
export const getFeatureOnPoint = (feature) => {
    if (!feature) {
        return;
    }
    try {
        switch (feature.geometry.type) {
            case "LineString":
                return pointOnFeature(lineString(feature.geometry.coordinates));
            case "Polygon":
                return pointOnFeature(polygon(feature.geometry.coordinates));
            case "MultiPolygon":
                return pointOnFeature(multiPolygon(feature.geometry.coordinates));
            default:
        }
    }
    catch (error) {
        console.info(error);
    }
};
