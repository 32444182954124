import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle `
  body,
  #root {
    height: var(--app-height);
    width: 100vw;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #282828;
  }

  * {
    scrollbar-width: thin;
    font-family: 'Inter', serif !important;
  }

  a {
    color: #5732d3;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  ::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.75rem;
    background-color: #f7f7f7;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #d9d9d9;
    border: 0.25rem solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.36);
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
