import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { InputLabel, StatisticsFiltersContainer, StatisticsTableContainer, StatisticsWrapper } from "./styled";
import { Flex, FlexSpan, Select, TextInput } from "../../ui";
import { EMPTY_VALUE } from "../../constants/common";
import { useIndustiesOptions } from "./hooks/useIndustiesOptions";
import { formatDate } from "../../utils/format";
import { api } from "../../api";
export const UsersTab = ({ visible }) => {
    const [users, setUsers] = useState([]);
    const industriesOptions = useIndustiesOptions();
    const [search, setSearch] = useState("");
    const [selectedIndustry, setSelectedIndustry] = useState();
    const filteredUsers = useMemo(() => {
        return users
            .filter(user => search
            ? user.name.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase())
            : true)
            .filter(user => (selectedIndustry !== undefined ? user.industry_id === selectedIndustry : true));
    }, [users, search, selectedIndustry]);
    useEffect(() => {
        api.users.getUsers().then(response => setUsers(response.reverse()));
    }, []);
    return (_jsxs(StatisticsWrapper, { visible: visible, children: [_jsx(StatisticsFiltersContainer, { children: _jsxs(Flex, { alignItems: "center", gap: "2rem", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { column: true, gap: "0.5rem", children: [_jsx(FlexSpan, { children: _jsx(InputLabel, { children: "Search" }) }), _jsx(FlexSpan, { children: _jsx(TextInput, { placeholder: "Enter user name or email...", width: "240px", value: search, onChange: setSearch }) })] }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, gap: "0.5rem", children: [_jsx(FlexSpan, { children: _jsx(InputLabel, { children: "Industry" }) }), _jsx(FlexSpan, { children: _jsx(Select, { width: "330px", options: industriesOptions, defaultValue: industriesOptions[0], value: industriesOptions.find(item => item.value === selectedIndustry), onChange: option => setSelectedIndustry(option.value) }) })] }) })] }) }), _jsx(StatisticsTableContainer, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "User name" }), _jsx("th", { children: "User email" }), _jsx("th", { children: "Industry" }), _jsx("th", { children: "Registered" })] }) }), _jsx("tbody", { children: filteredUsers.map(user => {
                                return (_jsxs("tr", { children: [_jsx("td", { children: user.name }), _jsx("td", { children: user.email }), _jsx("td", { children: user.industry?.title || EMPTY_VALUE }), _jsx("td", { children: formatDate(user.created_at) || EMPTY_VALUE })] }, `user-${user.id}`));
                            }) })] }) })] }));
};
