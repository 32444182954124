export class Events {
    http;
    constructor(http) {
        this.http = http;
    }
    getEvents() {
        return this.http.get(`/api/events`).json();
    }
    createEvent(data) {
        return this.http.post(`/api/events`, data);
    }
}
