import styled from "styled-components";
export const MapContainer = styled.div `
  width: 100%;
  height: 100%;

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    width: 350px;
  }

  .mapboxgl-ctrl-bottom-left {
    visibility: hidden;
  }

  .mapboxgl-ctrl-bottom-right {
    visibility: hidden;
  }

  .mapboxgl-ctrl-top-left {
    position: absolute;
    z-index: 3;
  }

  .mapboxgl-ctrl-top-right {
    position: absolute;
    z-index: 3;
  }
`;
