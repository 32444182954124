import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { Flex, FlexSpan } from "../Grid";
import { TabContainer, TabLabel } from "./styled";
export const Tabs = ({ items = [], defaultValue, onChange }) => {
    const [value, setValue] = useState();
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    return (_jsx(Flex, { children: items.map((item, index) => (_jsx(Fragment, { children: _jsx(FlexSpan, { flexGrow: 1, flexBasis: "50%", children: _jsx(TabContainer, { active: value === item.value, onClick: () => {
                        setValue(item.value);
                        onChange?.(item.value);
                    }, children: _jsx(TabLabel, { children: item.label }) }) }) }, `tabs-item-${index}`))) }));
};
