import styled from "styled-components";
import { Flex } from "../../ui";
import { SelectionModes } from "./components/ModeControl/types";
import plusIcon from "../../assets/icons/plus.svg";
import minusIcon from "../../assets/icons/minus.svg";
import locationIcon from "../../assets/icons/location.svg";
import cursor from "../../assets/icons/cursor.png";
export const LENS_BORDER_WIDTH = 4;
export const AppWrapper = styled(Flex) `
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: var(--app-height);
  background-color: #f6f7ff;

  #oneCityMainMap {
    position: relative;
    width: 100vw;
    height: calc(var(--app-height) - 3.5rem);
    z-index: 1;
    border: 1px solid #ffffff;
    border-radius: 1.5rem 1.5rem 0 0;

    .mapboxgl-canvas-container.mapboxgl-interactive {
      cursor: ${({ selectionMode }) => selectionMode === SelectionModes.Click && "default"};
    }

    .mapboxgl-ctrl-top-right {
      display: flex;
      flex-direction: column-reverse;
      background-color: #ffffff;
      top: calc(50% - 86px);
      right: 13px;
      box-shadow: 0 0 11px rgba(0, 0, 0, 0.24);
      border-radius: 16px;
      padding: 4px;

      @media only screen and (max-width: 768px) {
        & {
          right: 8px;
        }
      }

      .mapboxgl-ctrl.mapboxgl-ctrl-group {
        margin: 0;
        box-shadow: none;

        button {
          background: #5732d3;
          border-radius: 12px;
          width: 36px;
          height: 36px;

          @media only screen and (max-width: 768px) {
            & {
              width: 36px;
              height: 36px;
            }
          }

          @media (hover: hover) {
            &:hover {
              background-color: #dde0ff;
            }
          }

          @media (hover: none) {
            &:active {
              background-color: #dde0ff;
            }
          }
        }

        .mapboxgl-ctrl-zoom-in {
          @media (hover: hover) {
            &:hover {
              .mapboxgl-ctrl-icon {
                background-color: #5732d3;
              }
            }
          }

          @media (hover: none) {
            &:active {
              .mapboxgl-ctrl-icon {
                background-color: #5732d3;
              }
            }
          }

          .mapboxgl-ctrl-icon {
            background-image: none;
            mask: url(${plusIcon}) no-repeat 100% 100%;
            mask-position: 11px 11px;
            background-color: white;

            @media only screen and (max-width: 768px) {
              & {
                mask-position: 11px 11px;
              }
            }
          }
        }

        .mapboxgl-ctrl-zoom-out {
          border-top: none;
          margin-top: 2px;

          @media (hover: hover) {
            &:hover {
              .mapboxgl-ctrl-icon {
                background-color: #5732d3;
              }
            }
          }

          @media (hover: none) {
            &:active {
              .mapboxgl-ctrl-icon {
                background-color: #5732d3;
              }
            }
          }

          .mapboxgl-ctrl-icon {
            background-image: none;
            mask: url(${minusIcon}) no-repeat 100% 100%;
            mask-position: 6px 7px;
            background-color: white;

            @media only screen and (max-width: 768px) {
              & {
                mask-position: 6px 7px;
              }
            }
          }
        }

        .mapboxgl-ctrl-geolocate {
          margin-bottom: 16px;

          @media only screen and (max-width: 768px) {
            & {
              margin-bottom: 16px;
            }
          }

          @media (hover: hover) {
            &:hover {
              .mapboxgl-ctrl-icon {
                background-color: #5732d3;
              }
            }
          }

          @media (hover: none) {
            &:active {
              .mapboxgl-ctrl-icon {
                background-color: #5732d3;
              }
            }
          }

          .mapboxgl-ctrl-icon {
            background-image: none;
            mask: url(${locationIcon}) no-repeat 100% 100%;
            mask-position: 8px 10px;
            background-color: white;

            @media only screen and (max-width: 768px) {
              & {
                mask-position: 8px 10px;
              }
            }
          }
        }
      }
    }
  }

  #oneCityLens {
    position: absolute;
    z-index: 2;
    border: ${() => `${LENS_BORDER_WIDTH}px`} solid;
    filter: brightness(95%);

    @media only screen and (max-width: 768px) {
      & {
        z-index: 2;
      }
    }

    .mapboxgl-canvas-container.mapboxgl-interactive {
      cursor: ${({ selected }) => (!selected ? `url(${cursor}) 11 11, auto` : "none")};
    }

    .mapboxgl-control-container {
      display: none;
    }
  }
`;
