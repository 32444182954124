import styled from "styled-components";
import { FlexSpan } from "../Grid";
export const TabLabel = styled(FlexSpan) `
  position: relative;
`;
export const TabContainer = styled.div `
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  box-sizing: border-box;
  border-bottom: 0.25rem solid ${({ active }) => (active ? "#5732d3" : "#F6F5F8")};
  transition: 0.15s ease-in-out;
  background-color: ${({ active }) => (active ? "#5732d3" : "#F6F5F8")};
  color: ${({ active }) => (active ? "#ffffff" : "initial")};
  cursor: pointer;
  height: 2.625rem;
  flex-wrap: nowrap;
  white-space: nowrap;

  &[disabled] {
    cursor: not-allowed;
  }

  &:hover {
    color: ${({ active }) => !active && "#5732d3"};
  }
`;
