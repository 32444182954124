import styled from "styled-components";
import { Flex, FlexSpan } from "../../../../ui";
export const ResultDialogContainer = styled.div `
  position: absolute;
  top: 70px;
  left: 20px;
  width: 400px;
  height: auto;
  max-height: calc(var(--app-height) - 9.25rem);
  overflow: hidden;
  background-color: #ffffff;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 5;
  border-radius: 1rem;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);

  @media only screen and (max-width: 768px) {
    & {
      top: 54px;
      left: 8px;
      width: calc(100% - 16px);
      box-sizing: border-box;
      padding-top: 20px;
    }
  }

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;

    @media only screen and (max-width: 430px) {
      & {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.011em;
    margin: 8px 0 0;
  }
`;
export const DialogHeader = styled(Flex) `
  flex-direction: column;
  gap: 1rem;

  @media only screen and (max-width: 768px) {
    & {
      flex-direction: row;
    }
  }
`;
export const ValueCard = styled(Flex) `
  flex-direction: column;
  justify-content: space-between;
  min-height: 5.375rem;

  @media only screen and (max-width: 430px) {
    & {
      min-height: 2.4375rem;
    }
  }
`;
export const DialogLabel = styled(FlexSpan) `
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: #8275ad;
`;
export const DialogValue = styled(FlexSpan) `
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  word-break: break-word;

  @media only screen and (max-width: 430px) {
    & {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;
export const FloatTools = styled.div `
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 1.5rem);
  top: 0.75rem;
  left: 0.75rem;
  z-index: 1;
`;
export const Preview = styled.div `
  width: 100%;
  height: 290px;
  background: ${({ imageSrc }) => `url("${imageSrc}") center no-repeat no-repeat`};
  background-size: cover;
  border-radius: 0.75rem;

  @media only screen and (max-width: 768px) {
    & {
      height: 200px;
    }
  }
`;
